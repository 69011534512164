import axios from "axios";
import config from "../config/suscripcion.config";

const me = () => axios.get(`${config.host}/auth/me`);
const editarMe = (paylaod, id) =>
  axios.put(`${config.host}/usuarios/${id}`, paylaod);

export default {
  me,
  editarMe,
};
