<template>
  <form class="w-100" @submit.prevent="submit">
    <div class="row">
      <div class="col-md-8 col-sm-12">
        <div>
          <div class="card p-3">
            <div class="p-5">
              <h4 class="text-blue">Agregar invitado</h4>
            </div>
            <div class="card-body">
              <div class="w-100">
                <img
                  class="rounded-circle border-2 border border-blue img-fluid"
                  src="media/avatars/blank.png"
                  alt="avatar"
                  style="width: 131px"
                />
              </div>

              <div class="row" action="">
                <div class="my-2 d-block">
                  <label class="text-gray my-1" for="nombre">Nombre</label>
                  <input
                    id="nombre"
                    v-model="model.nombre"
                    type="text"
                    placeholder="Ingrese nombre del invitado"
                    class="p-3 bg-gray-input border-0 rounded-1 text-gray w-100"
                    name="nombre"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="nombre" />
                    </div>
                  </div>
                </div>
                <div class="my-2 d-block">
                  <label class="text-gray my-1" for="correo">Email</label>
                  <input
                    id="correo"
                    type="text"
                    placeholder="Ingrese el correo del invitado"
                    class="p-3 bg-gray-input border-0 rounded-1 text-gray w-100"
                    name="correo"
                    v-model="model.correo"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="correo" />
                    </div>
                  </div>
                </div>
                <div class="my-2 d-block">
                  <label class="text-gray my-1" for="movil"
                    >Numero de contacto</label
                  >
                  <input
                    id="movil"
                    v-model="model.movil"
                    type="text"
                    placeholder="Ingrese el teléfono del invitado"
                    class="p-3 bg-gray-input border-0 rounded-1 text-gray w-100"
                    name="movil"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="movil" />
                    </div>
                  </div>
                </div>
                <div class="my-2 d-block">
                  <label class="text-gray my-1" for="clave"
                    >Asignar contraseña</label
                  >
                  <input
                    id="clave"
                    v-model="model.clave"
                    type="text"
                    placeholder="Ingrese el teléfono del invitado"
                    class="p-3 bg-gray-input border-0 rounded-1 text-gray w-100"
                    name="clave"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="clave" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-12" style="height: 80vh">
        <div class="card p-1 h-75 overflow-auto">
          <div class="p-5 border-bottom d-flex">
            <h4 class="text-blue me-2 w-50">Asignar grupos</h4>
            <div class="form-control d-flex align-items-center p-0 w-50">
              <i class="bi bi-search mx-2"></i>
              <input
                class="border-0 w-50"
                type="text"
                placeholder="Buscar"
                name=""
                id=""
              />
            </div>
          </div>
          <div
            class="card-body p-2"
            v-for="(grupo, e) in grupos"
            :key="e"
            id="grupos"
          >
            <div class="d-flex my-2 justify-content-between me-2">
              <div class="d-flex align-items-center">
                <div class="symbol symbol-50px me-3">
                  <div class="symbol-label bg-light rounded-circle">
                    <img
                      src="media/svg/brand-logos/blank.png"
                      alt=""
                      class="h-50"
                    />
                  </div>
                </div>
                <div>
                  <a
                    href="#"
                    class="fs-6 text-gray-800 text-hover-blue fw-bolder"
                    >{{ grupo.nombre }}</a
                  >
                </div>
              </div>
              <div class="d-flex align-items-center">
                <input
                  class="form-check-input"
                  v-model="grupoId"
                  :value="grupo._id"
                  type="checkbox"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="d-block my-4 h-25">
          <button
            class="btn bg-blue text-white rounded-pill w-100"
            type="submit"
            :disabled="isLoading"
          >
            <span v-if="isLoading">Procesando...</span>
            <span v-else>Añadir invitado</span>
          </button>
          <button type="button" class="btn btn-light rounded-pill w-100">
            Cancelar
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ErrorMessage, useField, useForm } from "vee-validate";
import * as Yup from "yup";
import miGrupoService from "@/services/miGrupo.service";
import authService from "@/services/auth.service";

export default defineComponent({
  name: "mi-grupo-agregar-invitado",
  components: {
    ErrorMessage,
  },
  setup() {
    const grupos = ref([]);
    const grupoId = ref([]);
    const traerGrupos = () => {
      miGrupoService.listarGrupos().then((data) => {
        console.log(data);
        grupos.value = data.data.data;
      });
    };
    const isLoading = ref(false);
    const validationSchema = Yup.object().shape({
      correo: Yup.string()
        .email("correo deberia ser válido")
        .required("Este campo es requerido"),
      nombre: Yup.string().required("Este campo es requerido"),
      movil: Yup.string().required("Este campo es requerido"),
      clave: Yup.string().required("Este campo es requerido"),
    });
    const {
      handleReset,
      handleSubmit,
      values: model,
    } = useForm({
      validationSchema,
    });
    useField("nombre", null, { initialValue: "" });
    useField("correo", null, { initialValue: "" });
    useField("movil", null, { initialValue: "" });
    useField("clave", null, { initialValue: "" });
    useField("suscripcionId", null, { initialValue: "" });
    useField("rol", null, { initialValue: 2 });
    const submit = handleSubmit((values) => {
      values.grupoIds = grupoId.value.join(",");
      miGrupoService
        .invitadoAgrear(values)
        .then((data) => {
          console.log(data);
          handleReset();
          Swal.fire("Enviado", "El usuario ha sido invitado", "success");
        })
        .catch((err) => {
          console.log(err.response.data.message);
          Swal.fire("Error", err.response.data.message, "error");
        });
    });
    const traerDatos = () => {
      authService.me().then((data) => {
        console.log(data);
        model.suscripcionId = data.data.suscripcionId;
      });
    };
    onMounted(() => {
      setCurrentPageBreadcrumbs("Agregar Invitado", ["Pages", "Mi grupo"]);
      traerGrupos();
      traerDatos();
    });
    return { submit, model, isLoading, grupos, grupoId };
  },
});
</script>
<style scoped>
.border-blue {
  border-color: #27aae1 !important;
}
.text-gray {
  color: #999999;
}
.bg-blue {
  background-color: #27aae1;
}
.bg-gray-input {
  background-color: #f4f8fb;
}
</style>
